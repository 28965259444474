import { reactive, toRefs } from 'vue'

export default function () {
  const state = reactive({
    timer: null,
    isdisabled: false,
    counttetx: '获取验证码'
  })

  const refState = toRefs(state)
  const countdown = () => {
    const TIME_COUNT = 60
    if (!state.timer) {
      let count = TIME_COUNT
      state.isdisabled = true
      state.timer = setInterval(() => {
        if (count > 0 && count <= TIME_COUNT) {
          state.counttetx = count-- + 's'
        } else {
          state.isdisabled = false
          state.counttetx = '获取验证码'
          clearInterval(state.timer)
          state.timer = null
        }
      }, 1000)
    }
  }

  return {
    ...refState,
    countdown
  }
}